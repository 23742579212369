.container-article {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 3.75rem;
  margin-top: 5rem;
  margin-bottom: 2.25rem;
  article {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@include desktop {
  .container-article {
    flex-wrap: wrap;
    article {
      margin-bottom: 5rem;
    }
  }
}
