@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin tablette {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobile-large {
  @media screen and (min-width: 440px) {
    @content;
  }
}

@mixin mobile-small {
  @media screen and (max-width: 440px) {
    @content;
  }
}