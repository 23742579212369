.table-container {
  min-height: 12.5rem;
  border: 1px solid $title;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 31.25rem;
  width: 100%;
  div {
    width: 100%;
  }
}

.table-title {
  font-size: 1.5em;
  margin-bottom: 0.62rem;
}

.title-row {
  color: $title;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .table-cell {
    text-align: center;
    margin-bottom: 0.95rem;
  }
}

.body-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 400px;
  .table-cell {
    flex-wrap: wrap;
    margin-bottom: 0.62rem;
    width: 150px;
  }
}
