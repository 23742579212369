.btn-don {
  padding: 0.62rem 1.25rem;
  border-radius: 50px;
  text-align: center;
  color: $white;
  &:hover {
    background-color: #42b972fe;
  }
  a:hover {
    color: $white;
  }
}

#btn-don-presentation {
  transition: transform 0.3s ease;
  margin-top: 1.25rem;
  &:hover {
    transform: scale(1.2);
  }
}

@include tablette {
  .btn-don {
    
  }
}