nav {
  ul {
    display: flex;
    font-size: $font-size-text-small;
    column-gap: 1.87rem;
    align-items: center;
    
    li {
      transition: transform 0.3s ease;
      a {
        font-weight: 300;
      }
      a:hover {
        color: $primary; 
      }
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.menu-burger {
  display: none;
}

@include tablette {
  .menu-burger-style {
    @include center;
    flex-direction: column;
    row-gap: 1.87rem;
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .menu-burger:hover {
    cursor: pointer;
  }
  .menu-burger {
    display: flex;
  }
  .menu-desktop {
    display: none;
  }
}