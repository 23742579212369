.section-list-info {
  height: 6.25rem;
  display: flex;
  align-items: center;
  padding-left: 5rem;
}

@include tablette {
  .section-list-info {
     padding-left: 0;
     justify-content: center;
   }
}