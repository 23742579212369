.background-footer {
  background-color: $background-footer;
}

.line-gray {
  border-top: 1px solid $second-text;
  padding-top: 0.75rem;
  padding-bottom: 1.75rem;
}

#footer {
  color: $second-text;
  align-items: flex-start;
  li {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    column-gap: 1.25rem;
    h3 {
      color: $white;
      width: 6.25rem;
      margin: 0;
    }
  }
}
