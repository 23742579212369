* {
  font-family: $font-family-style;
  margin: 0;
  font-weight: 400;
}

#root {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

main {
  width: 100%;
}

.container {
  @include center;
  width: 100%;
  flex-direction: column;
  padding: 6.25rem 3.75rem;
  box-sizing: border-box;
}
@include tablette {
  .container {
    padding: 3.75rem 1.87rem;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.87rem;
  max-width: 1200px;
  width: 100%;
  p {
    text-align: left;
  }
}

.style-one {
  color: $white;
  span {
    color: $li-text;
  }
}

.style-two {
  color: $text;
  span {
    color: $primary;
  }
}

h2 {
  font-size: $font-size-titles-h2;
}

h3 {
  font-size: $font-size-titles-h3;
  margin-bottom: 1.68rem;
  color: $li-text;
}

.style-h3 {
  color: $white;
}

p {
  font-size: $font-size-text;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

.background-green {
  background: $background-image;
}

.icon-rounded {
  border: 2px solid $primary;
  color: $primary;
  width: 8.75rem;
  height: 8.75rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.37rem;
  margin-bottom: 2.2rem;
}

.image {
  border-radius: 4px;
  border: 0;
  display: inline-block;
  position: relative;
}

.image img {
  border-radius: 4px;
  display: block;
}

.image.left {
  float: left;
  padding: 0 1.5em 1em 0;
  top: 0.25em;
}

.image.right {
  float: right;
  padding: 0 0 1em 1.5em;
  top: 0.25em;
}

.image.left,
.image.right {
  max-width: 40%;
}

.image.left img,
.image.right img {
  width: 100%;
}

.logo {
  cursor: pointer;
}

@include mobile-small {
  h2 {
    font-size: $font-size-titles-h3;
  }
  p {
    font-size: $font-size-text-small;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
