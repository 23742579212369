.container-form {
  @include center;
  flex-direction: column;
  row-gap: 1.25rem;
  max-width: 1200px;
  width: 100%;
}

.style-form {
  width: 100%;
  box-sizing: border-box;
}

.row-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 1.25rem;
}

input,
select {
  height: 3.43rem;
  padding: 0 1.25rem;
}

textarea {
  height: 12.37rem;
  padding: 0.93rem 1.25rem;
}

.custom-selector {
  position: relative;

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }
  @include mobile-large {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 30%;
      width: 0.8rem;
      height: 0.8rem;
      border: solid $second-text;
      border-width: 0 4px 4px 0;
      transform: rotate(45deg);
      margin-right: 1.25em;
      right: 0.7em;
      cursor: pointer;
      pointer-events: none;
    }
  }
}

input,
select,
textarea {
  color: $text;
  font-size: $font-size-text-small;
  font-weight: 300;
  border-radius: 4px;
  border: 1px solid $second-text;
}

.container-actions {
  @include center;
  flex-direction: row;
  column-gap: 1.25rem;
}

.btn-primary {
  width: 100%;
  height: 3.43rem;
  border-radius: 4px;
  background-color: $white;
  color: black;
  font-size: $font-size-text-small;
  font-weight: 300;
  border: 0.5px solid $primary;
  cursor: pointer;
}

@include mobile-small {
  .row-form {
    flex-direction: column;
    row-gap: 1.25rem;
  }
}