.btn {
  background-color: $primary;
  border-radius: 0.25rem;
  padding: 0 2.5rem;
  color: black;
  font-size: $font-size-text;
  &:hover {
    background-color: $primary;
    color: white;
    cursor: pointer;
  }
  &-big {
    padding: 0 3.37rem;
    background-color: transparent;
    border: 2px solid rgba(145, 138, 138, 0.558);
    font-weight: 300;
    height: 4.81rem;
  }
}

@include tablette {
  .btn {
    
    &-big {
      padding: 0 2.5rem;
    }
  }
}
