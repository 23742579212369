.header {
  padding: 0 2.25rem;
  margin: 1.87rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  
  justify-content: space-between;
  box-sizing: border-box;
  p {
    color: $primary;
    font-size: 1.56rem;
  }
}

@include desktop {
  .header {
    flex-direction: column;
    justify-content: center;
    padding: 0 1.25rem;
    p {
      margin-bottom: 1.87rem;
    }
  }
}

@include tablette {
  .header {
    text-align: center;
    justify-content: space-between;
    flex-direction: row;
    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
}

@include mobile-large {
  .header {
    p {
      align-items: center;
    }
  }
}