.container-background {
  background-image: url("../../assets/banner.avif");
  background-color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.hero {
  height: 70.4vh;
  padding: 0;
}

.container-slogan {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.87rem;
  color: $white;
  margin-bottom: 5rem;
  text-align: center;
  button {
    margin-top: 1.87rem;
  }
  h1,
  h2 {
    font-weight: 300;
  }
}

.slogan {
  font-size: 3.75rem;
}

.container-style-green {
  height: 10vh;
  width: 100%;
  background: $background-banner-image;
}

@include desktop {
  .container-slogan {
    row-gap: 0.95rem;
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 1.25rem;
    }
  }
}

@include tablette {
  .container-slogan {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 2rem;
    h1 {
      font-size: 1.75rem;
    }
    h2 {
      font-size: 1rem;
    }
  }
}
