.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  margin: 20% auto;
  padding: 1.25rem;
  border: 1px solid #888;
  width: 80%;
  max-width: 25rem;
  border-radius: 25px;
}

.modal-content-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 1.75rem;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

.text-modal-style {
  color: $second-text;
  padding: 2.5rem;
}
